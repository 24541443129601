<template>
  <v-form ref="form">
    <v-row>
      <v-col>
        <v-text-field
          label="Cod. Identificador"
          v-model="formCad.id_empresa"
          :rules="[rules.required, rules.numeric, rules.max8]"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <BaseSelect
          label="Empresa Matriz"
          v-model="formCad.id_matriz"
          :items="selectItems.itemsMatriz"
          :multiple="false"
          @itemSelect="formCad.id_matriz = $event"
          :rules="[rules.notNULL, rules.numeric]"
        />
      </v-col>
      <v-col>
        <v-text-field
          label="Schema"
          v-model="formCad.schema"
          :rules="[rules.required]"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Razão Social"
          v-model="formCad.nome"
          :rules="[rules.required, rules.max60]"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Fantasia"
          v-model="formCad.nome_fantasia"
          :rules="[rules.max60]"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Apelido"
          v-model="formCad.apelido"
          :rules="[rules.required, rules.max5]"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="CNPJ"
          v-model="formCad.cnpj"
          :rules="[rules.required, rules.is14]"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <BaseSelect
          label="Bandeira"
          v-model="formCad.id_band"
          :items="selectItems.itemsBandeira"
          :multiple="false"
          @itemSelect="formCad.id_band = $event"
          :rules="[rules.notNULL, rules.numeric]"
        />
      </v-col>
      <v-col>
        <BaseSelect
          label="Região"
          v-model="formCad.id_regiao"
          :items="selectItems.itemsRegiao"
          :multiple="false"
          @itemSelect="formCad.id_regiao = $event"
          :rules="[rules.notNULL, rules.numeric]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <BaseSelect
          label="Grupo Empresa"
          v-model="formCad.id_grupo_empresa"
          :items="selectItems.itemsGP"
          :multiple="false"
          @itemSelect="formCad.id_grupo_empresa = $event"
          :rules="[rules.notNULL, rules.numeric]"
        />
      </v-col>
      <v-col>
        <v-text-field
          label="DN Honda"
          v-model="formCad.cod_dn_honda"
          :rules="[rules.max20]"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="E-mail"
          v-model="formCad.email"
          :rules="[rules.email, rules.max100]"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from "@/mixins/inputRules";
import serviceEmpresaMatriz from "@/services/empresa";
import serviceBandeira from "@/services/empresa";
import serviceRegiao from "@/services/empresa";
import serviceGrupoEmpresa from "@/services/empresa";
import { mapActions } from "vuex";

export default {
  name: "FormCadastroEmpresa",

  mixins: [rules],

  components: {
    BaseSelect: () => import("@/components/shared/BaseSelect")
  },

  data() {
    return {
      selectItems: {
        itemsMatriz: [],
        itemsBandeira: [],
        itemsRegiao: [],
        itemsGP: []
      },
      formCad: {
        id_empresa: "",
        id_matriz: "",
        schema: "",
        nome: "",
        apelido: "",
        cnpj: "",
        nome_fantasia: "",
        email: "",
        cod_dn_honda: "",
        id_band: "",
        id_regiao: "",
        id_grupo_empresa: ""
      },
      rules: {
        notNULL: notNULL => {
          return (
            (notNULL && notNULL.length != 0) || "O campo não pode ser vazio"
          );
        },

        max5: max5 => {
          return (
            (max5 && max5.length <= 5) ||
            "O campo deve conter no máximo 5 dígitos"
          );
        },

        max8: max8 => {
          return (
            (max8 && max8.length <= 8) ||
            "O campo deve conter no máximo 8 dígitos"
          );
        },

        is14: is14 => {
          return (
            (is14 && is14.length == 14) || "O campo deve conter 14 dígitos"
          );
        },

        max20: max20 => {
          return (
            max20.length == 0 ||
            (max20 && max20.length <= 20) ||
            "O campo deve conter no máximo 20 dígitos"
          );
        },

        max60: max60 => {
          return (
            max60.length == 0 ||
            (max60 && max60.length <= 60) ||
            "O campo deve conter no máximo 60 dígitos"
          );
        },

        max100: max100 => {
          return (
            (max100 && max100.length <= 100) ||
            "O campo deve conter no máximo 100 dígitos"
          );
        }
      }
    };
  },

  watch: {
    formCad: {
      handler() {
        this.fillSetFormEmpresa(this.formCad);
      },
      deep: true
    }
  },

  methods: {
    ...mapActions("empresa", ["fillSetFormEmpresa"]),

    async selectEmpresaMatriz() {
      try {
        const response = await serviceEmpresaMatriz.getEmpresasMatrizes();
        response.data.data.forEach(matriz => {
          const item = {
            text: matriz.nome,
            value: matriz.id_matriz
          };
          this.selectItems.itemsMatriz.push(item);
        });
      } catch (error) {
        console.error(error);
      }
    },

    async selectBendeira() {
      try {
        const response = await serviceBandeira.getBandeiras();
        response.data.data.forEach(bandeira => {
          const item = {
            text: bandeira.descricao,
            value: bandeira.id_band
          };
          this.selectItems.itemsBandeira.push(item);
        });
      } catch (error) {
        console.error(error);
      }
    },

    async selectRegiao() {
      try {
        const response = await serviceRegiao.getRegioes();
        response.data.data.forEach(regiao => {
          const item = {
            text: regiao.regiao,
            value: regiao.id_regiao
          };
          this.selectItems.itemsRegiao.push(item);
        });
      } catch (error) {
        console.error(error);
      }
    },

    async selectGrupoEmpresa() {
      try {
        const response = await serviceGrupoEmpresa.getGrupoEmpresa();
        response.data.data.forEach(grupoEmpresa => {
          const item = {
            text: grupoEmpresa.grupo_empresa,
            value: grupoEmpresa.id_grupo_empresa
          };
          this.selectItems.itemsGP.push(item);
        });
      } catch (error) {
        console.error(error);
      }
    },

    validateForm() {
      return this.$refs.form.validate();
    }
  },

  mounted() {
    this.selectEmpresaMatriz();
    this.selectBendeira();
    this.selectRegiao();
    this.selectGrupoEmpresa();
  }
};
</script>

<style></style>
